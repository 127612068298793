.login-root {
  height: 100vh;
  background-image: url(./img/loginBg.svg);
  background-position: center;
  display: flex;

  align-items: center;
  justify-content: center;


  .login-inner {
    border-radius: 32px;
    background: #fff url(./img/loginBg3.png) no-repeat;
    background-size: auto 100%;
    border-radius: 32px;
    width: 1024px;
    height: 500px;
    border: 1px solid #e4ebef;
    box-shadow: 2px 2px 1px #e4ebef;

    display: flex;
    flex-direction: row-reverse;
    align-items: stretch;
  }

  .login-form {
    display: flex;
    width: 50%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .login-title {
    font-size: 36px;
    letter-spacing: 4px;
    font-weight: 600;
    color: #1890ff;
    line-height: 2;
  }

  .login-sub-title {
    color: #1890ff;
    font-size: 24px;
    letter-spacing: 2px;
  }


  .ant-input {
    line-height: 3;
  }
}
@layout-header-height: 48px;@layout-header-color: #fff;