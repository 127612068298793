.app-root {
  height: 100vh;
}

.app-header {
  display: flex;
  align-items: center;
  padding: 0 24px;
  overflow: hidden;
  z-index: 20;

  .app-icon {
    height: 66%;
    margin-top: 8px;
    margin-right: 8px;
  }

  .app-title {
    font-size: 24px;
    margin-right: 48px;
  }
}

.app-sider {
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #fff;
  box-shadow: 1px 0px 4px #888888;
  z-index: 10;
}

.app-content {
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 0;
}

.app-breadcrumb {
  background-color: #fff;
  padding: 4px 24px;
  color: #888;
  position: fixed;
  width: calc(100vw - 200px);
  z-index: 100;
  border-bottom: 12px solid #F0F2F5;
}

.content-root {
  padding: 40px 16px 16px 16px;
}

.card-h-margin {
  margin-bottom: 12px;
}


.lf {
  float: left;
}

.rf {
  float: right;
}

.clearFloat:after {
  content: "";
  display: block;
  clear: both;
}
@layout-header-height: 48px;@layout-header-color: #fff;