body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.flex-grow-1 {
  flex-grow: 1;
}

.ant-descriptions-item-container {
  border-bottom: 1px dashed #ddd;
  padding: 8px;
}

.ant-descriptions-item-label {
  font-weight: bold;
}


::-webkit-scrollbar {
  width: 4px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #eee;
}

::-webkit-scrollbar-thumb {
  background: #00daf9;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #a2e7f0;
}

.statusTxt {
  font-size: 16px;
  color: magenta;
  line-height: 53px;
}

.myCard {
  .ant-card-head {
    border-bottom: none;
  }
}

.formButton {
  position: fixed;
  top: 88px;
  left: 225px;
  z-index: 10;
  width: calc(100vw - 400px);
  padding-top: 10px;
  height: 60px;
  background: #fff;

  .anticon {
    position: relative;
    top: 3px;
  }

  .ant-btn {
    margin-left: 10px;
    font-size: 16px;
    padding: 0 20px;
    height: 40px;
    line-height: 30px;
    letter-spacing: 2px;
  }

}

.vertical-checkgroup {
  .ant-checkbox-wrapper {
    display: flex;
    margin-left: 0 !important;
  }
}
@layout-header-height: 48px;@layout-header-color: #fff;